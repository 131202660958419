.modalStyles {
  --anchor-font-size: var(--font-size-body);
  --heading-line-height: var(--font-size-2xl);
  --modal-padding: 0;
  --modal-panel-display: flex;
  --modal-panel-flex-direction: column;
  --modal-padding-screen-md: 0;
  --modal-panel-padding: 0;
  --modal-panel-wrap-height: 100%;
  --modal-panel-wrap-padding: 0;
  --modal-close-button-padding: var(--space-5x) var(--space-5x) 0 0;
  --thank-you-message-size: var(--font-size-sm);
  --listing-card-preview-border-radius: var(--border-radius-2x);

  --horizontal-scroll-arrows-top: 8px;
  display: flex;
  flex-direction: column;

  @media (--supports-mouse) {
    --horizontal-scroll-list-overflow-x: hidden;
  }

  @media (--screen-md) {
    --modal-height: calc(100% - var(--space-12x));
    --modal-margin: auto;

    @media (--screen-md) and (min-height: 860px) {
      --modal-height: 812px;
    }
  }
}

.navHeader {
  --anchor-placement: center;
  --anchor-display: flex;
  --anchor-gap: var(--space-1-5x);
  --modal-close-button-position: relative;
  --modal-close-button-padding: 0;
  display: flex;
  justify-content: space-between;
  padding: var(--space-4x) var(--space-5x) var(--space-5x);

  & a {
    cursor: pointer;
  }
}

.hideBackButton {
  justify-content: flex-end;

  & a:nth-of-type(1) {
    display: none;
  }
}

.formStep {
  @media (--screen-md) {
    --modal-width: 400px;
  }
}

.similarPropertiesStepBase {
  --modal-panel-overflow: hidden auto;
  @media (--screen-md) {
    --modal-width: 570px;
  }

  @media (--screen-lg) {
    --modal-width: 879px;
  }
}

.similarPropertiesStep {
  @media (--screen-md) and (min-height: 860px) {
    --modal-panel-height: auto;
    --modal-height: fit-content;
  }
}

.similarPropertiesStepRedesign {
  @media (--screen-md) and (min-height: 860px) {
    --modal-height: 812px;
  }
}
